import React from "react"
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import { Button, Container, Row, Form } from 'react-bootstrap';
import HTMLReactParser from 'html-react-parser';
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import AreaGuideSidebar from "../components/Sidebar/AreaGuideSidebar";
import Modules from '../components/modules';
import AreaGuideBanner from "../components/Home/Banner/AreaGuideBanner";
import StaticContact from "../components/Home/ContactUs/StaticContact";
import FeaturedProperties from "../components/Home/Properties/Properties";
import Reviews from "../components/Home/Reviews/Reviews";
import OtherAreaGuides from "../components/collections/OtherAreaGuides";
import Map from "../components/map/google/single"
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";





import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";


const AreaTemplate = (props) => {

	const [state, setState] = React.useState({
		showMenu: false
	})

	const handlerClick = () => {
		setState({ ...state, showMenu: !state.showMenu })
	}

	const GQLPage = props.data.glstrapi?.areaGuide;
	const ArticlePage = props.data.glstrapi?.article;
	const Site = props.data.glstrapi?.siteConfig;
	let reviewImage = { url: "https://ggfx-dachare.s3.eu-west-2.amazonaws.com/i.dev/todd_kent_178j8t_Jr_Nlc_unsplash_1_ad1a0c26f2.png" };
	console.log(ArticlePage)
	let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
	return (
		<div className="homepage">
			<SEO title={`Living in ${GQLPage.Title} | ${GQLPage.Title} Area Guide`} description={GQLPage.Meta_Description ? GQLPage.Meta_Description : `Here is the insight about the in and around of ${GQLPage.Title} by Dacha Real Estate, which will help you to decide about property investment in ${GQLPage.Title}, Dubai.  `} location={props.location}/>

			<div className={`${state.showMenu ? "open-search-block" : "area-guide-detail"} ${language === 'Russian' ? "russian-page" : ""}`}>
				<Header showMenu={state.showMenu} handlerClick={handlerClick} fixed={true} />
				<AreaGuideBanner id={GQLPage.id} page={GQLPage} url={GQLPage.URL} content={GQLPage.Header_Content} image={GQLPage.Banner_Image} info={GQLPage.Header_Call_Info} cta_1={GQLPage.Header_CTA_1_Label} cta_2={GQLPage.Header_CTA_2_Label} cta_1_link={GQLPage.Header_CTA_1_Link} cta_2_link={GQLPage.Header_CTA_2_Link} video={GQLPage.Video_URL} />
				<Container className={`static-content-landing pad-50 ${GQLPage.Banner_Image === null ? 'no-banner' : ''}`}>
					<Row>
						<div className="col-xl-8 col-md-12 plain-content">
							{GQLPage.Content &&
								<>
									{HTMLReactParser(GQLPage.Content.replace(/<br\s*\/?>/gi, ''))}
								</>
							}
						</div>
						<div className="col-xl-4 col-md-12">
							<AreaGuideSidebar content={GQLPage.Sidebar_Content} peoples={GQLPage.Sidebar_People} site={Site} />
						</div>
					</Row>
				</Container>
				{GQLPage.Latitude ?
					<Map lat={GQLPage.Latitude ? parseFloat(GQLPage.Latitude) : ''} lng={GQLPage.Longtitude ? parseFloat(GQLPage.Longtitude) : ''} height={"600px"} zoom={12} OfficeName={GQLPage.Title} /> : ''}
				<FeaturedProperties place={GQLPage.Title} />
				{
					ArticlePage.Modules && ArticlePage.Modules.map((module, i) => {
						return (
							<>
								{
									module.Reviews_Background_Image &&
									<Reviews image={module.Reviews_Background_Image} imagetransforms={ArticlePage?.imagetransforms} />
								}
							</>
						)


					})
				}
				{/* <Reviews image={reviewImage} /> */}
				<OtherAreaGuides id={GQLPage.id} />
				<StaticContact />
				<Breadcrumb data={''} tag={`detail-page`} name={GQLPage.Title} category={`Area Guide`} />
				<NewsLetter />
				<Footer />
				{/* <StickyFooter /> */}
			</div>


		</div>
	)
}

export default AreaTemplate;

export const pageQuery = graphql`
  query AreaQuery($id: ID!) {
    glstrapi {
		areaGuide(id: $id, publicationState: LIVE) {
		  Banner_Image {
			alternativeText
			url
		  }
		  Content
		  Header_CTA_1_Label
		  Header_CTA_1_Link {
			URL
			Label
		  }
		  Header_CTA_2_Label
		  Header_CTA_2_Link {
			Label
			URL
		  }
		  Header_Call_Info
		  Header_Content
		  Latitude
		  Longtitude
		  Meta_Description
		  Meta_Title
		  Publish
		  Tile_Image {
			alternativeText
			url
		  }
		  Title
		  URL
		  Video_URL
		  id
		  createdAt
		  published_at
		  Sidebar_Content
		  Sidebar_People {
			URL
			Tile_Image {
			  url
			}
			Name
			Phone
			Designation
		  }
		  imagetransforms
		}
		article(id: "60a4bf70123ae87ffba01add") {
			Modules {
				... on GLSTRAPI_ComponentModuleGoogleReviews {
					id
					Reviews_Background_Image {
					  alternativeText  
					  url
					}
				  }
			}
			imagetransforms
		}
		siteConfig {
			Address
			Email
			Phone
			Available_Timings
		  }
		
	  }   
  }
`
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import { Container, Row } from 'react-bootstrap';
import PlayVideo from "../Play/custom-video";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const OtherAreaGuides = (props) => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
	    areaGuides(where: {Publish: true}) {
	      id
	      Title
	      URL
	      Video_URL
		  imagetransforms
	      Tile_Image {
	        url
	        alternativeText
	      }
	    }
      }
    }
  `);

  const area_guides = data.glstrapi.areaGuides; 
  const areas = area_guides.filter(guides => guides.id != props.id).slice(0, 4); 
 

	return (
		<React.Fragment>
		{areas && areas.length > 0 &&
		<div className="other-area-guide-lisiting">
			<Container>
				<h3>Other Areas</h3>
				<Row> 
				{areas.map((data, i) => { 

                	const image_url = data.Tile_Image.url 

                	let processedImages = JSON.stringify({});
                	if (data?.imagetransforms?.Tile_Image_Transforms) {
                	    processedImages = data.imagetransforms.Tile_Image_Transforms;
                	} 
                   	return (
					<div className="col-lg-3 col-md-6">
						<Link to={`/living-in-dubai/${data.URL}`}>
						<div className="tile">
							<div className="image-video">
							{data.Tile_Image &&
                            	<ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Tile_Image.tile" attr={{ alt: data.Title+ ' - Dacha', class:'' }} imagetransformresult={processedImages} id={data.id}/>
							}
					          {data.Video_URL && 
					          <div className="banner-btn">
					          <PlayVideo url={data.Video_URL}
					          />
					          </div>
					          }
					          </div>
							<span className="title">{data.Title}</span>
						</div>
						</Link>
					</div>
					)
				})}
				</Row>
			</Container>
		</div>
		}
		</React.Fragment>
	)
}

export default OtherAreaGuides